<template>
  <desktop-layout>
    <header>
      <DesktopNavigation :name="'Вернуться к подбору номера'" :to="'/search'" />
    </header>
    <main class="main-page__body-m container">
      <div class="row page_info_row">
        <div class="col-6">
          <div class="page_info_radio_btn">
            <input
              id="radio-change"
              type="radio"
              name="infoPage"
              value="change"
              v-model="infoPage"
            />
            <label for="radio-change" class="mx-auto">
              <div class="page_info_label">
                {{ $t("nav.change") }}
              </div>
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="page_info_radio_btn">
            <input
              id="radio-reserve"
              type="radio"
              name="infoPage"
              value="reserve"
              v-model="infoPage"
            />
            <label for="radio-reserve" class="mx-auto">
              <div class="page_info_label">
                {{ $t("reserve.btn") }}
              </div>
            </label>
          </div>
        </div>
      </div>

      <div>
        <transition name="component-fade" mode="out-in">
          <component :is="pageInfoComponent" :info="compData"></component>
        </transition>
      </div>
    </main>
  </desktop-layout>
</template>

<script>
import DesktopLayout from "@/layouts/DesktopLayout";
import DesktopNavigation from "@/components/desktop/DesktopNavigation.vue";
import NumberChangeDesktop from "./NumberChangeDesktop";
import NumberReserveDesktop from "./NumberReserveDesktop";
import { mapGetters } from "vuex";

export default {
  name: "DesktopNumberInfo",
  props: ["msisdn", "categoryId"],
  data: () => ({
    infoPage: "change",
    info: {},
  }),
  computed: {
    ...mapGetters(["msisdnInfo", "numberClass"]),
    pageInfoComponent() {
      if (this.infoPage == "change") return NumberChangeDesktop;
      else return NumberReserveDesktop;
    },
    category() {
      let categoryId = this.categoryId;
      if (categoryId == undefined) {
        if(this?.info?.NCLS_ID === undefined) {
          window.location.href = '/';
        }
        categoryId = Number(this.info.NCLS_ID.substring(0, 1));
      }
      return this.numberClass(categoryId);
    },
    compData() {
      return {
        msisdn: this.msisdn,
        category: this.category,
        msisdn_info: this.info,
      };
    },
  },
  created() {
    this.info = this.msisdnInfo(this.msisdn);
  },
  components: {
    DesktopNavigation,
    DesktopLayout,
  },
};
</script>

<style>
.page_info_radio_btn input[type="radio"] {
  display: none;
}
.page_info_radio_btn label {
  cursor: pointer;
  user-select: none;
  opacity: 0.5;
  text-align: center;
  padding-bottom: 8px;
  margin: 0;
  width: 75%;
  display: block;
}
.page_info_radio_btn input[type="radio"]:checked + label {
  opacity: 1;
  border-bottom: 2px solid #71c343;
}
.page_info_label {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.page_info_row {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;
}
</style>
