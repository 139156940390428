<template>
  <div class="main-page__nav-d">
    <div class="container">
      <router-link :to="to" class="d-flex flex-row">
        <img
          src="@/assets/icons/arrow_left_d.png"
          width="14"
          height="14"
          class="d-block mr-2"
        />
        <div class="nav-d_title">{{ name }}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DesktopNavigation",
  props: ["to", "name"],
};
</script>

<style>
.main-page__nav-d {
  padding: 58px 0 24px 0;
}
.main-page__nav-d .nav-d_title {
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  opacity: .5;
}
.main-page__nav-d a:hover {
  text-decoration: none;
}
</style>