<template>
  <div>
    <div class="change_number_header d-flex flex-row">
      <div class="change_number_header_img">
        <img
            :src="require(`@/assets/icons/${info.category.img_alt}`)"
            width="64"
            height="64"
        />
      </div>
      <div class="align-self-center">
        <div class="change_number_value">
          {{ $t("change.number", [this.formattedMsisdn]) }}
        </div>
      </div>
    </div>
    <div class="change_number_info_d">
      <div class="change_number_info_d_row d-flex justify-content-between">
        <div class="change_number_info_d_label">{{ $t("info.category") }}</div>
        <div class="change_number_info_d_value">
          {{ $t(info.category.label_l) }}
        </div>
      </div>
      <div class="change_number_info_d_row d-flex justify-content-between">
        <div class="change_number_info_d_label">
          {{ $t("reserve.btn") }}
        </div>
        <div class="change_number_info_d_value">
          {{ prices.RESERVE }}
          <span class="currency">с</span>
        </div>
      </div>
      <div class="change_number_info_d_row d-flex justify-content-between">
        <div class="change_number_info_d_label">
          {{ $t("reserve.change") }}
        </div>
        <div class="change_number_info_d_value">
          {{ prices.CHANGE }} <span class="currency">с</span>
        </div>
      </div>
    </div>
    <div class="change_combination_help_text reserve_help_text">
      {{ $t("reserve.comb") }}
    </div>
    <div class="change_combination mt-4">{{ changeCombination }}</div>
    <div class="change_balance_help_text mt-2">
      {{ $t("reserve.bal") }}
    </div>
    <div class="order_sim_card_d d-flex flex-row align-items-end">
      <div><img src="@/assets/icons/sim.svg" width="51" height="40" /></div>
      <div>
        <a
            class="order_sim_card_d_link"
            target="_blank"
            :href="$t('info.order_sim_link')"
        >{{ $t("info.order_sim") }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import helperMixin from "@/mixins/helper.mixin";
import prices from "@/utils/prices";

export default {
  name: "NumberReserveDesktop",
  mixins: [helperMixin],
  props: {
    info: Object,
  },
  data: () => ({
    prices: prices,
  }),
  computed: {
    formattedMsisdn() {
      return this.info.msisdn.replace(
          /(\d{3})(\d{3})(\d{3})(\d{3})/,
          "$1 $2 $3 $4"
      );
    },
    categoryPrice() {
      let price = this.info.msisdn_info.CATEGORY_PRICE;
      return this.formatPrice(price);
    },
    numberDiscount() {
      let discount = this.info.msisdn_info.MSISDN_DISCOUNT ?? 0;
      if (discount == "0") {
        return discount;
      }
      return "- " + this.formatPrice(discount);
    },
    changeCombination() {
      let msisdn = this.info.msisdn.replace("996", "0");
      return `*250*2*${msisdn}#`;
    },
  },
};
</script>

<style>
.reserve_help_text {
  margin-top: 24px;
}
</style>
