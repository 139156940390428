<template>
  <div class="desktop-layout">
    <div
      class="container d-flex flex-row justify-content-between align-items-center"
    >
      <div>
        <a :href="`https://mega24.kg/${this.locale}`">
          <img src="@/assets/logo.svg" width="219" height="22" />
        </a>
      </div>
      <div><LanguageSwitcher /></div>
    </div>
    <main class="LayoutDesktop__main">
      <slot />
    </main>
    <div class="container desktop-footer">
      <ul class="nav nav-tabs nav-justified">
        <li class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('home')"
            :class="{ active: isActive('home') }"
            href="#home"
            >{{ $t("footer.desc_h") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            @click.prevent="setActive('profile')"
            :class="{ active: isActive('profile') }"
            href="#profile"
            >{{ $t("footer.rules_h") }}</a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('home') }"
          id="home"
          v-html="$t('footer.desc')"
        ></div>
        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('profile') }"
          id="profile"
          v-html="$t('footer.rules')"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "../components/LanguageSwitcher.vue";
import i18n from "../i18n";

export default {
  name: "desktop-layout",
  data: () => ({ activeItem: "home" }),
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
  computed: {
    locale: () => (i18n.locale === 'kg' ? 'ky' : 'ru'),
  },
  components: {
    LanguageSwitcher,
  },
};
</script>

<style>
.desktop-layout {
  padding-top: 32px;
}
.desktop-footer {
  margin-top: 50px;
}

.desktop-footer .nav-tabs .nav-link {
  padding: 16px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: rgba(30, 30, 30, 0.85);
  border-bottom: 2px solid transparent;
  transition: all 0.5s;
}

.desktop-footer .nav-tabs .nav-link:focus,
.desktop-footer .nav-tabs .nav-link:hover {
  border-color: #71c343;
}

.desktop-footer .nav-tabs .nav-link.active {
  border-bottom: 2px solid #71c343;
  color: rgba(30, 30, 30, 0.85);
}
.desktop-footer .tab-content {
  padding: 24px 0;
}

.desktop-footer .tab-content a {
  color: #000000;
  text-decoration: underline;
}
</style>